import {gql} from 'graphql.macro';

export const GET_USER = gql`
    query GetUser($id: Int!) {
        user(id: $id) {
            id
            name
            email
            profile
            declaredInstitution
            status
            institutionId
            receiveNotifications
        }
    }
`

export const SIGN_OUT = gql`
    mutation signOut($sessionToken: String!) {
        signOut(sessionToken: $sessionToken) {
            status
            message
        }
    }
`;

export const SUBSCRIBE_USER = gql`
    mutation SubscribeUser {
        subscribeUser {
            id
        }
    }
`;

export const UNSUBSCRIBE_USER = gql`
    mutation UnsubscribeUser {
        unsubscribeUser {
            id
        }
    }
`;
export const UPDATE_USER_STATUS = gql`
    mutation UpdateUserStatus(
        $userId: Int!,
        $status: String!,
    ) {
        updateUser(
            userId: $userId,
            status: $status
        ) {
            id
            status
        }
    }
`;
export const RESEND_USER_EMAIL_CONFIRMATION = gql`
    mutation ResendUserEmailConfirmation(
        $userId: Int!,
        $status: String!,
    ) {
        resendUserEmailConfirmation(
            userId: $userId,
            status: $status
        ) {
            id
            status
        }
    }
`;
export const UPDATE_USER = gql`
    mutation UpdateUser(
        $userId: Int!,
        $name: String,
        $declaredInstitution: String,
        $profile: String,
        $status: String,
        $password: String,
        $institutionId: Int,
    ) {
        updateUser(
            userId: $userId,
            name: $name,
            declaredInstitution: $declaredInstitution,
            profile: $profile,
            status: $status,
            password: $password,
            institutionId: $institutionId,
        ) {
            id
        }
    }
`;
export const CREATE_USER = gql`
    mutation signInCreateUser(
        $name: String,
        $declaredInstitution: String,
        $profile: String,
        $status: String,
        $password: String,
        $institutionId: Int,
    ) {
        signUp(
            name: $name,
            declaredInstitution: $declaredInstitution
            password: $password
        ) {
            message
        }
    }
`;
export const USERS = gql`
    query users(
        $page: Int,
        $limit: Int,
        $nameOrEmailOrInstitutionNameCont: String,
        $status: UserStatus,
        $profile: UserProfile,
    ) {
        users(
            page: $page,
            limit: $limit,
            nameOrEmailOrInstitutionNameCont: $nameOrEmailOrInstitutionNameCont,
            statusEq: $status,
            profileEq: $profile
        ) {
            metadata {
                totalCount
            }
            collection {
                id
                name
                email
                declaredInstitution
                profile
                status
                institutionId
                institution {
                    name
                }
            }
        }
    }
`;

export const SIGN_UP = gql`
    mutation SignUp(
        $name: String!,
        $email: String!,
        $declaredInstitution: String!,
        $password: String!,
    ) {
        signUp(
            name: $name,
            email: $email,
            declaredInstitution: $declaredInstitution,
            password: $password,
        ) {
            status
            message
        }
    }
`;
export const UNSUBSCRIBE_USER_FROM_EMAIL = gql`
    mutation UnsubscribeUserFromEmail($token: String!) {
        unsubscribeUserFromEmail(token: $token)
    }
`;
export const FORGOT_MY_PASSWORD = gql`
    mutation ForgotMyPassword($email: String!) {
        forgotMyPassword(email: $email) {
            status
            message
        }
    }
`;
export const SIGN_IN_AND_CREATE_TOKEN = gql`
    mutation SignIn($email: String!, $password: String!) {
        signIn(email: $email, password: $password) {
            token
        }
        createToken( email: $email, password: $password ){
            token
        }
    }
`;
export const UPDATE_MY_PROFILE = gql`
    mutation UpdateMyProfile(
        $name: String,
    ) {
        updateMyProfile(
            name: $name,
        ) {
            id
        }
    }
`;
export const UPDATE_MY_PASSWORD = gql`
    mutation UpdateMyPassword($currentPassword: String!, $newPassword: String!) {
        updateMyPassword(
            currentPassword: $currentPassword,
            newPassword: $newPassword
        ) {
            token
        }
    }
`;
export const RECOVERY_PASSWORD = gql`
    mutation RecoveryPassword($newPassword: String!, $recoveryToken: String!) {
        recoveryPassword(
            newPassword: $newPassword,
            recoveryToken: $recoveryToken
        ) {
            token
        }
    }
`;
export const CONFIRM_USER = gql`
    mutation ConfirmUser($confirmationToken: String!) {
        confirmUser(confirmationToken: $confirmationToken) {
            status
            message
        }
    }
`;