import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import palette from './palette.scss'

export const primaryColor = palette.primaryColor;
export const secondaryColor = palette.secondaryColor;
export const thirdColor = palette.thirdColor;
export const fourthColor = palette.fourthColor;
export const fifthColor = palette.fifthColor;
export const sixthColor = palette.sixthColor;
export const blackColor = palette.blackColor;
export const blackColorSecondary = palette.blackColorSecondary;
export const blackDisabled = palette.blackDisabled;
export const blackHint = palette.blackHint;
export const blackColorThird = palette.blackColorThird;
export const grayColor = palette.grayColor;
export const whiteColor = palette.whiteColor;
export const whiteTrueColor = palette.whiteTrueColor;

export const fontFamily = palette.fontFamily;

export const errorColor = palette.errorColor;
export const successColor = palette.sixthColor;

let theme = createTheme({
  palette: {
    common: {
      black: blackColor,
      white: whiteColor
    },
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    error: {
      main: errorColor
    },
    warning: {
      main: thirdColor
    },
    info: {
      main: fourthColor
    },
    success: {
      main: sixthColor
    },
    text: {
      primary: blackColor,
      secondary: blackColorSecondary,
      disabled: blackDisabled,
      hint: blackHint
    },
    background: {
      paper: grayColor,
      default: whiteTrueColor
    }
  },
  typography: {
    fontFamily: fontFamily,
    color: blackColorSecondary,
    body1: {
      color: blackColorSecondary
    },
    body2: {
      color: blackColorSecondary
    },
    h1: {
      fontSize: '20px',
      fontWeight: '600',
      color: fifthColor,
      padding: '0 0 10px',
      marginTop: '0',
      marginBottom: '20px',
      textTransform: 'uppercase',
      borderBottom: `2px solid ${fifthColor}`
    },
    h2: {

    },
    subtitle1: {
      color: secondaryColor
    },
    subtitle2: {
      color: blackColorSecondary
    },
    caption: {
      paddingTop: '1em',
      display: 'block',
      lineHeight: '1.3em',
      color: blackDisabled
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: false
    },
    MuiFormControl: {
      fullWidth: true
    },
    MuiInputLabel: {
      shrink: true
    }
  },
  overrides: {
    MuiInputAdornment:{
      positionStart: {
        marginRight: '0px'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '12px 14px'
      },
      adornedStart: {
        paddingLeft: '0px'
      }
    },
    MuiAutocomplete: {
      root: {
        '&.autocomplete-light': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${whiteColor} !important`
          },
          '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            color: whiteColor
          },
          '& .MuiInputLabel-shrink': {
            paddingLeft: 0,
            color: whiteColor,
            backgroundColor: grayColor
          },
          '& .MuiIconButton-root': {
            color: whiteColor,
          }
        }
      },
      inputRoot: {
        padding: 0,
      },
      noOptions: {
        fontSize: 14
      },
      option: {
        fontSize: 14
      },
      popper: {
        zIndex: 9999
      },
    },
    MuiList: {
      root: {
        backgroundColor: whiteTrueColor,
        borderTop: `2px solid ${fifthColor}`
      }
    },
    MuiButton: {
      root: {
      },
      contained: {
        paddingBottom: '5px',
        paddingTop: '5px',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none'
        },
        '&:focus': {
          boxShadow: 'none'
        }
      },
      containedSizeLarge: {
        paddingBottom: '6px',
        paddingTop: '6px',
        paddingLeft: '25px',
        paddingRight: '25px'
      },
      containedSizeSmall: {
        paddingBottom: '4px',
        paddingTop: '4px',
        paddingLeft: '15px',
        paddingRight: '15px'
      },
      startIcon: {
        marginRight: '16px'
      },
      text: {
        padding: '6px 16px'
      }
    },
    MuiDialog: {
      root: {
        zIndex: '5000 !important'
      },
      paper: {
        zIndex: '5000 !important',
        backgroundColor: whiteTrueColor
      },
      MuiPopover: {
        root: {
          zIndex: '5500 !important'
        }
      }
    },
    MuiPopover: {
      root: {
        zIndex: '5100 !important'
      }
    },
    MuiFab: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiFormControl: {
      root: {
        '&.input-label-gray': {
          '& .MuiInputLabel-shrink': {
            backgroundColor: 'white'
          },
        }
      }
    },
    MuiInputLabel: {
      root: {
        color: blackColor,
        fontWeight: 500
      },
      shrink: {
        padding: '0 6px',
        backgroundColor: whiteTrueColor
      }
    },
    MuiDataGrid: {
      root: {
        border: '',
        backgroundColor: whiteTrueColor
      },
      '& MuiDataGrid-cell:focus':{
        border: ''
      },
      '& MuiDataGrid-cell:focusWithin':{
        border: ''
      }
    },
    MuiInput: {
      root: {
      },
      formControl: {
        'label + &': {
          marginTop: 20
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: 0,
        fontSize: 14
      }
    },
    MuiGridFilterForm:{
      root: {
        backgroundColor: whiteTrueColor,
        borderTop: `2px solid ${fifthColor}`,
        '& .MuiFormControl-fullWidth': {
          width: 'inherit'
        },
        '& MuiFormLabel':{
          backgroundColor: whiteTrueColor
        }
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 40,
        fontSize: 14
      }
    },
    MuiGridMenu: {
      root: {
        zIndex: 7000
      }
    },
    MuiSelect: {
      root: {
        '& .MuiInputLabel-shrink': {
          paddingLeft: 0,
          color: whiteColor
        }
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTooltip: {
      popper: {
        zIndex: 3000
      }
    },
    MuiFormLabel: {
      root: {
        backgroundColor: whiteTrueColor
      },
    },
    MuiTable: {
      root: {
        minWidth: '250px'
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: whiteTrueColor
      }
    },
    MuiTableRow:{
      root: {
        backgroundColor: whiteTrueColor,
        '&:hover': {
          backgroundColor: whiteColor,
        }
      },
      '&.Mui-selected': {
        borderBottom: '1px solid black',
      }
    },
    MuiSlider:{
      markLabel:{
        fontSize: '10px',
        transform: 'rotate(-90deg)',
        marginLeft: '-5px'
      }
    },
    MuiDrawer:{
      root: {
        width: '340px',
        '&.MuiList': {
          root: {
            border: 'none',
            backgroundColor: whiteColor
          }
        }
      },
      paper: {
        width: '340px'
      },
      modal: {
        position: 'initial !important'
      }
    },
    Accordion: {
      root: {
        backgroundColor: grayColor,
        borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: 'none',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: '0',
        }
      }
    },
    AccordionSummary: {
      root: {
        padding: '0 12px',
        minHeight: 35,
        '&.Mui-expanded': {
          minHeight: 35,
          backgroundColor: whiteColor
        }
      },
      content: {
        '&.Mui-expanded': {
          margin: '12px 0',
        }
      },
      expandIcon: {
        padding: 4,
        '& svg': {
          fontSize: 24
        }
      }
    },
    MuiPaper: {
      root: {
        //backgroundColor: whiteColor
      },
      elevation1: {
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
      },
      '&.input-label-gray': {
        '& .MuiInputLabel-shrink': {
          backgroundColor: whiteColor
        },
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 9999
      }
    }
  },

});

export default responsiveFontSizes(theme);
