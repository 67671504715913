import React, { Suspense } from 'react';

import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import _, {
  map,
  concat,
  defaults,
} from 'lodash'

import AppContext from '../context/app-context'

import LoadingBasePage from '../../components/Loading/LoadingBasePage';

const AdminPage = React.lazy(() => import('../../pages/AdminPage'));
const APIPage = React.lazy(() => import('../../pages/APIPage'));
const ConfirmationPage = React.lazy(() => import('../../pages/ConfirmationPage'));
const CreatePassword = React.lazy(() => import('../../pages/CreatePassword'));
const CustomReportPage = React.lazy(() => import('../../pages/ReportCustomizationPage/ReportCustomizationPage'));
const DownloadsPage = React.lazy(() => import('../../pages/DownloadsPage'));
const GenerateReportsPage = React.lazy(() => import('../../pages/GenerateReportsPage'));
const LandingPage = React.lazy(() => import('../../pages/LandingPage'));
const LoginPage = React.lazy(() => import('../../pages/LoginPage'));
const PasswordRecovery = React.lazy(() => import('../../pages/PasswordRecovery'));
const PluginsPage = React.lazy(() => import('../../pages/PluginsPage'));
const ProfilePage = React.lazy(() => import('../../pages/ProfilePage'));
const ReportPage = React.lazy(() => import('../../pages/ReportPage'));
const NewReportPage = React.lazy(() => import('../../pages/NewReportPage'));
const SignUp = React.lazy(() => import('../../pages/SignUp'));
const UnsubscribeNotificationsPage = React.lazy(() => import('../../pages/UnsubscribeNotificationsPage'));
const DocumentationPage = React.lazy(() => import('../../pages/DocumentationPage'));
const StatisticsPage = React.lazy(() => import('../../pages/StatisticsPage'));
const NotFoundPage = React.lazy(() => import('../../pages/NotFoundPage'));
const ReportValidatorPage = React.lazy(() => import('../../pages/ReportValidatorPage'));
const SandBoxPage = React.lazy(() => import('../../pages/SandBoxPage'));
const LostConnectionPage = React.lazy(() => import('../../pages/LostConnectionPage'));
const ManutencePage = React.lazy(() => import('../../pages/ManutencePage'));
const ShapePage = React.lazy(() => import('../../pages/ShapePage'));
const SupportCasesPage = React.lazy(() => import('../../pages/SupportCasesPage'));
const InspectionMonitorPage = React.lazy(() => import('../../pages/InspectionMonitorPage'));
const AuthenticatedReportsPage = React.lazy(() => import('../../pages/AuthenticatedReportsPage'));
const UploadActionsPage = React.lazy(() => import('../../pages/UploadActionsPage'));
const ActionsBatchPage = React.lazy(() => import('../../pages/ActionsBatchPage'));
const TermsAndPrivacityPage = React.lazy(() => import('../../pages/TermsAndPrivacityPage'));
const AlertByStatusPage = React.lazy(() => import('../../pages/AlertByStatusPage'));
export let routes_paths = []
export default function Routes() {

  const routes = {
    unauthenticated: [
      { path: '/', component: LandingPage, title: 'pages.home'},
      { path: '/downloads', component: DownloadsPage, title: 'pages.download'},
      { path: '/plugins', component: PluginsPage, title: 'pages.plugins'},
      { path: '/documentacao', component: DocumentationPage, title: 'pages.documentation'},
      { path: '/confirmacao', component: ConfirmationPage, title: 'pages.confirmation'},
      { path: '/mapa', component: LandingPage, title: 'pages.map'},
      { path: '/lista', component: LandingPage, title: 'pages.list'},
      { path: '/laudos-e-acoes', component: LandingPage, title: 'pages.reports_and_actions'},
      { path: '/estatisticas', component: LandingPage, title: 'pages.dashboard'},
      { path: '/sign-in', component: LoginPage, title: 'pages.sign_in'},
      { path: '/sign-up', component: SignUp, title: 'pages.sign_up'},
      { path: '/cadastrar-senha', component: CreatePassword, title: 'pages.password_new'},
      { path: '/recuperar-senha', component: PasswordRecovery, title: 'pages.password_recovery'},
      { path: '/laudos/validador', component: ReportValidatorPage, title: 'pages.validator' },
      { path: '/laudos/validador/:code', component: ReportValidatorPage, title: 'pages.validador'},
      { path: '/laudo/:alertId/car/:carId', redirect: true, to: '/alerta/:alertId/carId/:carId', title: 'pages.reports'},
      { path: '/laudo/:alertId', redirect: true, to: '/alerta/:alertId', title: 'pages.reports'},
      { path: '/legacy/laudo/:alertId', component: ReportPage, title: 'pages.reports'},
      { path: '/legacy/laudo/:alertId/car/:carId', component: ReportPage, title: 'pages.reports'},
      { path: '/alerta/:alertId', component: NewReportPage, title: 'pages.reports'},
      { path: '/alerta/:alertId/carId/:carId', component: NewReportPage, title: 'pages.reports'},
      { path: '/alerta/:alertId/:type/:carCode', component: NewReportPage, title: 'pages.reports'},
      { path: '/alerta/:alertId/:type/:carCode', component: NewReportPage, title: 'pages.reports'},
      { path: '/alerta/:alertId/:type/:carCode', component: NewReportPage, title: 'pages.reports'},
      { path: '/shape', component: ShapePage, title: 'pages.shape'},
      { path: '/shape/:alertId', component: ShapePage, title: 'pages.shape'},
      { path: '/shape/:alertId/car/:carId', component: ShapePage, title: 'pages.shape'},
      { path: '/shape/:alertId/sigef/:carId', component: ShapePage, title: 'pages.shape'},
      { path: '/shape/:alertId/snci/:carId', component: ShapePage, title: 'pages.shape'},
      { path: '/raio-x-dos-alertas', component: StatisticsPage, title: 'pages.dashboard'},
      { path: '/404', component: NotFoundPage, title: 'pages.404'},
      { path: '/loading', component: LoadingBasePage, title: 'pages.loading'},
      { path: '/lost-connection', component: LostConnectionPage, title: 'pages.connection_lost'},

      { path: '/monitor-da-fiscalizacao', component: InspectionMonitorPage, title: 'pages.inspection_monitor' },

      { path: '/laudos/:alertId/car/:carId', redirect: true, to: '/alerta/:alertId/carId/:carId', title: 'pages.reports'},
      { path: '/laudos/:alertId', redirect: true, to: '/alerta/:alertId', title: 'pages.reports'},
      { path: '/meus-laudos', redirect: true, to: '/account/authenticated-reports', title: 'pages.reports'},
      { path: '/laudo-customizavel', redirect: true, to: '/institution/customizable-report', title: 'pages.reports_custom'},
      { path: '/admin', redirect: true, to: '/administrator', title: 'pages.administrator_area'},
      { path: '/validador', redirect: true, to: '/laudos/validador', title: 'pages.validador'},
      { path: '/validador/:code', redirect: true, to: '/laudos/validador/:code', title: 'pages.validador'},
      
      { path: '/cancelar-notificacoes', component: UnsubscribeNotificationsPage, title: 'pages.unsubscribe_notifications' },
      
      { path: '/laudos', redirect: true, to: '/laudos-e-acoes', title: ''},
      { path: '/login', redirect: true, to: '/sign-in', title: ''},
      { path: '/perfil', redirect: true, to: '/account/profile', title: ''},
      { path: '/account', redirect: true, to: '/account/profile', title: ''},
      { path: '/cadastre-se', redirect: true, to: '/sign-up', title: ''},
      { path: '/visualizacao-de-laudo', redirect: true, to: '/validador', title: 'Visualização de Laudos'},
      { path: '/api', component: APIPage, title: 'pages.api' },

      { path: '/terms', component: TermsAndPrivacityPage, title: 'pages.terms_and_privacity' },
      { path: '/privacity', component: TermsAndPrivacityPage, title: 'pages.terms_and_privacity' },
      { path: '/alert_by_status', component: AlertByStatusPage, title: 'pages.terms_and_privacity' },
      
      { path: '*', component: NotFoundPage, title: 'Página não encontrada'},
      
    ],
    authenticated: [
      
      { path: '/sandbox', component: SandBoxPage, title: 'pages.sandbox'},

      
      { path: '/api/v1', component: APIPage, title: 'pages.api' },
      { path: '/api/v1/documentation', component: APIPage, title: 'pages.api_documentation' },
      { path: '/api/v1/sandbox', component: APIPage, title: 'pages.api_sandbox' },
      { path: '/api/v2/sandbox', component: APIPage, title: 'pages.api_sandbox' },
      { path: '/api/v1/graphiql', component: APIPage, title: 'pages.api_sandbox' },
      { path: '/api/v2/graphiql', component: APIPage, title: 'pages.api_sandbox' },
      
      { path: '/institution/customizable-report', component: CustomReportPage, title: 'pages.reports_custom' },
      { path: '/institution/upload-actions', component: UploadActionsPage, title: 'pages.upload_actions' }, 
      { path: '/institution/upload-action/:actionsBatchId', component: ActionsBatchPage, title: 'pages.upload_actions' },
      
      { path: '/institution/upload-batch/actions', component: UploadActionsPage, title: 'pages.upload_actions' },
      { path: '/institution/upload-batch/action/:uploadBatchId', component: ActionsBatchPage, title: 'pages.upload_actions' },
      { path: '/institution/upload-batch/authorizations', component: UploadActionsPage, title: 'pages.upload_actions' },
      { path: '/institution/upload-batch/authorization/:uploadBatchId', component: ActionsBatchPage, title: 'pages.upload_actions' },

      
      { path: '/gerador-de-laudos', component: GenerateReportsPage, title: 'pages.reports_generator' },
      { path: '/gerador-de-laudos/lista', component: GenerateReportsPage, title: 'pages.reports_generator' },
      { path: '/gerador-de-laudos/laudos', component: GenerateReportsPage, title: 'pages.reports_generator' },
      
      { path: '/alerta/:alertId/support-case', component: SupportCasesPage, title: 'pages.support_case' },
      { path: '/alerta/:alertId/car/:carId/support-case', component: SupportCasesPage, title: 'pages.support_case' },
      

      { path: '/institution', component: ProfilePage, title: 'pages.profile' },

      { path: '/account/profile', component: ProfilePage, title: 'pages.profile' },
      { path: '/account/my-maps', component: ProfilePage, title: 'pages.profile' },
      { path: '/account/authenticated-reports', component: AuthenticatedReportsPage, title: 'pages.my_authenticated_reports' },
      { path: '/account/support-cases', component: SupportCasesPage, title: 'pages.support_cases' },
      { path: '/account/support-case/:id', component: SupportCasesPage, title: 'pages.support_case' },

      { path: '/administrator', component: AdminPage, title: 'pages.administrator_area' },
      { path: '/administrator/users', component: AdminPage, title: 'administrator.tabs.users' },
      { path: '/administrator/institutions', component: AdminPage, title: 'administrator.tabs.institutions' },
      { path: '/administrator/authenticated-reports', component: AdminPage, title: 'administrator.tabs.authenticated_reports' },
      { path: '/administrator/support-cases', component: AdminPage, title: 'administrator.tabs.support_cases' },
      { path: '/administrator/publish-alert', component: AdminPage, title: 'administrator.tabs.publish_alerts' },
      { path: '/administrator/upload-actions', component: UploadActionsPage, title: 'pages.upload_actions' },
      { path: '/administrator/upload-batch/actions', component: UploadActionsPage, title: 'pages.upload_actions' },
      { path: '/administrator/upload-batch/authorizations', component: UploadActionsPage, title: 'pages.upload_actions' },
    ],
  }
  routes.redirect = routes.authenticated.map((route)=>{return { path: route.path, redirect: true, to: '/sign-in?callback_url='+route.path}})


  routes_paths = localStorage.getItem('token') !== null ? concat(routes.authenticated,routes.unauthenticated) : concat(routes.redirect,routes.unauthenticated)

  return (
    <BrowserRouter>
      <AppContext.Provider value={ '' }>
        <Suspense fallback={ <LoadingBasePage /> }>
          <Switch>
            {
              map(routes_paths, (route, i) => {
                let routeProps = defaults({}, route, { key: i, exact: true })
                if (route.redirect === true) {
                  return <Redirect from={ route.path } to={ route.to } key={ i } />
                }else{
                  return <Route { ...routeProps } />
                }
              })
            }
          </Switch>
        </Suspense>
      </AppContext.Provider>
    </BrowserRouter>
  )
}
