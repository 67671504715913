export default {
  monthRange: ['2019-01', '2020-03'],
  dateType: 'detected_at',
  territoryType: 'all',
  territory: null,
  territoryIds: [],
  crossing: 'all',
  size: 'all',
  authorization: 'all',
  embargoed: 'all',
  deforestationClasses: ['All'],
  actionTypesIds: ['All'],
  sources: ['All']
};
