import {gql} from 'graphql.macro';

export const GET_NOTIFYUSER_NEW_TERMOFUSE = gql`
    query getNotifyUserNewTermOfUse{
    app @client {
      notifyUserNewTermOfUseVersion
    }
  }
`;

export const GET_CURRENT_USER_ID = gql`
    query GetUserID {
        currentUserId @client
    }
`;

export const GET_LOADING = gql`
    query getLoading{
        app @client {
            isLoadingBaseData
        }
    }
`;

export const GET_ACTIVE_ALERT_FILTERS_AND_LOADING = gql`
    query getActiveAlertFiltersAndLoading{
        app @client {
            activeAlertPosition
            activeAlertProperties
            activeFiltersTab
            activeTerritoryAlert
            alertIsVisible
            openTourGuided
            layersOpacity
            filtersAreVisible
            defaultFilterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
            isLoadingBaseData
        }
    }
`;

export const GET_PRIVATE_ACTIONS_TYPE = gql`
    query getPrivateActionsTyp{
        privateActionTypes
    }
`;

export const GET_ACTIVE_ALERT_AND_ALL_FILTERS = gql`
    query getActiveAlertAndAllFilters{
        app @client {
            activeAlertPosition
            activeAlertProperties
            activeAlertCode
            activeAlertBoxTab
            isLoadingAlertData
            activeTerritoryAlert
            alertIsVisible
            filtersAreVisible
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                actionTypesIds
                deforestationClasses
                sources
                map
            }
        }
    }
`;
export const GET_MAX_MIN_MONTH_AND_FILTERS = gql`
    query getMaxMinMonthAndFilters{
        app @client {
            maxMonth
            minMonth
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
            showAlertFiltersWarning
        }
    }
`;
export const GET_MAX_MIN_MONTH_AND_STATISTICS = gql`
    query getMaxMinMonthAndStatistics{
        app @client {
            maxMonth
            minMonth
            statisticsParams {
                monthRange
                territoryType
                territory
                territoryLabel
                territoryIds
                dataType
            }
            showAlertFiltersWarning
        }
    }
`;
export const GET_ACTIVEALERT_MAPFLY_AND_FILTERS = gql`
    query getActiveAlertMapflyAndFilters{
        app @client {
            activeAlertPosition
            activeAlertProperties
            activeAlertCode
            activePlanetPeriodKey
            activeMapbiomasPeriodKey
            activeFiltersTab
            alertsListIsVisible
            activeTerritoryAlert
            alertIsVisible
            isLoadingAlertData
            mapFlyTo
            mapPointInfo
            layersOpacity
            filtersAreVisible
            statisticsAreVisible
            hasAlertData
            totalOfAlerts
            defaultFilterParams{
              monthRange
              dateType
              territoryType
              territory
              territoryIds
              crossing
              size
              authorization
              embargoed
              locationType
              locationText
              activeAlertCode
              activeBaseMap
              activeLayers
              actionTypesIds
              deforestationClasses
              sources
              map
            }
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                actionTypesIds
                deforestationClasses
                sources
                map
            }
        }
    }
`;
export const GET_ACTIVE_ALERT_AND_FILTERS_VISIBLE = gql`
    query getActiveAlertAndFiltersVisible{
        app @client {
            activeAlertPosition
            alertIsVisible
            filtersAreVisible
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                actionTypesIds
                deforestationClasses
                sources
                map
            }
        }
    }
`;
export const GET_ACTIVE_ALERT_FILTERS_AND_TOTAL_OF_ALERTS = gql`
    query getActiveAlertFiltersAndTotalOfAlerts{
        app @client {
            activeAlertPosition
            alertIsVisible
            filtersAreVisible
            totalOfAlerts
            defaultFilterParams
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                actionTypesIds
                deforestationClasses
                sources
                map
            }
        }
    }
`;
export const GET_ACTIVE_ALERT_FILTERS_MAX_MIN_MONTH = gql`
    query getActiveAlertFilterMaxMinMonth{
        app @client {
            activeAlertPosition
            alertIsVisible
            filtersAreVisible
            maxMonth
            minMonth
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
        }
    }
`;
export const GET_ACTIVE_ALERT_TERRITORY_ALERT_AND_FILTERS = gql`
    query getActiveAlertTerritoryAlertAndFilters{
        app @client {
            activeAlertPosition
            activeTerritoryAlert
            alertIsVisible
            filtersAreVisible
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
        }
    }
`;
export const GET_TOAST = gql`
    query getToast{
        toast @client {
            content
            severity
            visible
        }
    }
`;
export const GET_ACTIVE_ALERT_VISIBLE_AND_BATCH_EXPORT_REPORTS = gql`
    query getActiveAlertVisibleAndBatchExportReports {
        app @client {
            activeAlertPosition
            alertIsVisible
            isLoadingAlertData
            isDoingBatchExportReports
            totalOfAlerts
            filtersAreVisible
            mobileMenuIsVisible
            statisticsAreVisible
            alertsListIsVisible
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
        }
    }
`;

export const GET_ACTIVE_ALERT_POSITION_AND_FILTERS = gql`
    query getActiveAlertPositionAndFilters{
        app @client {
            activeAlertPosition
            alertIsVisible
            alertsListIsVisible
            filtersAreVisible
            filterParams {
                monthRange
                dateType
                territoryType
                territory
                territoryIds
                crossing
                size
                authorization
                embargoed
                locationType
                locationText
                activeAlertCode
                activeBaseMap
                activeLayers
                sources
                actionTypesIds
                deforestationClasses
                map
            }
        }
    }
`;
export const GET_CURRENT_USER_AND_INSTITUTION = gql`
    query GetUserAndInstitution($institutionId: Int!) {
        currentUserId @client
        institution(id: $institutionId) {
            id
            name
            domains {
                id
                name
            }
            abbreviation
            logoUrl
            reportStructureSnapshot
        }
    }
`;
