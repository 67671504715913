import React from 'react';
import {useApolloClient, useQuery} from '@apollo/react-hooks';

import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';

import {GET_TOAST} from '../../models/InMemoryCache/localFields';

export default function ToastContainer() {
  const client = useApolloClient();
  const {data: baseData} = useQuery(GET_TOAST);

  if (!baseData) {
    return null;
  }

  const handleClose = () => {
    client.writeData({
      data: {
        toast: {
          __typename: 'ToastState',
          visible: false
        }
      }
    });
  };

  const {content, severity, visible} = baseData.toast;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={ visible }
      autoHideDuration={ 5000 }
      onClose={ handleClose }
      TransitionComponent={ Fade }
    >
      <Alert
        elevation={ 1 }
        onClose={ handleClose }
        severity={ severity || 'info' }
      >
        { content }
      </Alert>
    </Snackbar>
  );
}
